import { PERMISSION_NAME } from '@/constants/constants'

export default [
  {
    title: 'Trang chủ',
    icon: 'HomeIcon',
    route: 'trang-chu',
    root: true,
    permissionName: [PERMISSION_NAME.TRANG_CHU],
  },
]
