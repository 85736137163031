/*

NOTE: tag is not supported in horizontal menu

Array of object

Top level object can be:
2. Group (Group can have navItems as children)
3. navItem

* Supported Options

/--- nav Grp (top level grp is called header group) ---/

title
icon (if it's on top/second level)
children

/--- nav Item (top level item is called header link) ---/

icon (if it's on top/second level)
title
route: [route_obj/route_name] (I have to resolve name somehow from the route obj)

*/
import trangChu from '../../modules/trang-chu/navigation/horizontal/trang-chu'
// import quanLyQuyTrinh from '../../modules/quan-ly-quy-trinh/navigation/horizontal/ho-so'
// import qldm from '../../modules/danh-muc/navigation/horizontal/trang-chu'
// import tnhs from '../../modules/tiep-nhan-ho-so/navigation/horizontal/tiep-nhan-ho-so'
// import tkqhs from '../../modules/tra-ket-qua/navigation/horizontal/tra-ket-qua'
// import nkq from '../../modules/nhan-ket-qua/navigation/horizontal/nhan-ket-qua'
// import tracuu from '../../modules/tra-cuu/navigation/horizontal/tc-ho-so'
// import xlhs from '../../modules/xu-ly-ho-so/navigation/horizontal/index'
// import mc from '../../modules/mot-cua/navigation/horizontal/trang-chu'
// import bctk from '../../modules/bao-cao-thong-ke/navigation/horizontal/trang-chu'

// Array of sections
export default [...trangChu]
